import { ApiHelper } from "./helper/ApiHelper";
const apiHelper = new ApiHelper();
export class DigitalPoemServices {
    constructor() {}
    getChartData(screenName, instance, graphName, kpiMarket) {
        const uri = `aimo/digitalpoem/getGraphdata?screenName=${screenName}&instance=${instance}&graphTitle=${graphName}&kpiMarket=${kpiMarket}`
        return apiHelper.get(uri);
    }
    getVariance(growthVal, market) {
        const uri = `aimo/digitalpoem/get-variance?growthVal=${growthVal}&market=${market}`
        return apiHelper.get(uri)
    }
    getPredictedData(data) {
        const uri = `aimo/digitalpoem/load-json?filename=${data.fileName}&growthVal=${data.growthVal}&market=${data.market}`
        return apiHelper.post(uri, data)
    }
    getPredictedThirdLevel(key, market, data, edited, thirdlevelvalue) {
        if (thirdlevelvalue === undefined) {
            thirdlevelvalue = 0
        }
        const uri = `aimo/digitalpoem/getdata?key=${key}&values=&kpiMarket=${market}&edited=${edited}&thirdlevelvalue=${thirdlevelvalue}`
        return apiHelper.post(uri, data);
    }
    getVarianceAfterEdit(revenue, market, growthVal) {
        const uri = `aimo/digitalpoem/get-varianceAfterEdtit?revenue=${revenue}&market=${market}&growthValue=${growthVal}`
        return apiHelper.get(uri)
    }
    getAgilePlan(predictedrevenue, predictedbudget, variance) {
        const uri = `aimo/digitalpoem/get-agileplan?predictedrevenue=${predictedrevenue}&predictedbudget=${predictedbudget}&variance=${variance}`
        return apiHelper.get(uri)
    }
    getAgileChartData(fileName, market, data) {
        const uri = `aimo/digitalpoem/load-json-agile?fileName=${fileName}&kpiMarket=${market}`
        return apiHelper.post(uri, data)
    }
}